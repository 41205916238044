<template>
  <main flex flex-col relative min-h-full bg-neutral-400 dark:bg-neutral-100 w-full>
    <div fixed top-10 right-10 z-10>
      <FColorModeSwitch />
    </div>
    <div grow overflow-x-hidden relative mt-1rem>
      <slot />
    </div>
  </main>
</template>
